import dayjs from "dayjs";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type UseDateFormatProps = {
  date: Date;
  shouldShowDayOfWeek?: boolean;
};

/**
 * Custom hook to format a date into a localized string.
 *
 * @param {Date} props.date - The date to be formatted.
 * @param {boolean} [props.shouldShowDayOfWeek=false] - Flag to include the day of the week in the formatted string.
 * @returns {string} The formatted date string.
 */
export default function useDateFormat({
  date,
  shouldShowDayOfWeek = false,
}: UseDateFormatProps): string {
  const { t, i18n } = useTranslation();

  const formattedString = useMemo(() => {
    const month = dayjs(date).format("MMMM");
    const day = dayjs(date).format("D");
    const year = dayjs(date).format("YYYY");
    const translateMonth = t(`home:months.${month}`);
    const dayOfWeek = t(`home:days.${dayjs(date).format("dddd")}`);

    if (shouldShowDayOfWeek) {
      return dayOfWeek;
    } else {
      return t("common:dateFormat", {
        month: translateMonth,
        date: day,
        year,
      });
    }
  }, [date, shouldShowDayOfWeek, t, i18n.language]);

  return formattedString;
}
