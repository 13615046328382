import { MySphLightboxHelper } from "@sphtech/web2-core/auth";
import Cookies from "js-cookie";
import { ReactElement, useEffect } from "react";
import {
  getLanguageByPubId,
  getServiceFlagsByPubsId,
  NO_AUTH_PUBLICATION,
  PublicationShortname,
} from "src/app/auth/serviceIdentifier";
import { DefaultLightboxDelay } from "src/app/utils";

import assertEnum from "../../../../.web2/web2-helpers/assertEnum";
import { COOKIE_ISSUE_ID, COOKIE_KEY_PUBS_ID } from "../Home";
import { getIssueById } from "../Home/api";
import styles from "./Concurrency.module.scss";

export default function ConcurrencyPage(): ReactElement {
  const onContinue = async (pubId: PublicationShortname, issueId: string) => {
    const issue = await getIssueById({
      pubId,
      issueId: parseInt(issueId),
    });
    if (issue == null || typeof issue.readerUrlWithPubs !== "string") {
      throw new Error(`Reader URL cannot be found :: ${JSON.stringify(issue)}`);
    }
    const readerUrl = new URL(issue.readerUrlWithPubs);
    window.location.href = readerUrl.toString();
  };

  useEffect(() => {
    async function concurrencyLimit() {
      try {
        /**
         * In order to ensure that the default MySPH error screen can be rendered
         * (via `onError` in the catch block), we need to ensure that we run some
         * async code before any error is possibly thrown.
         *
         * Rather than making sure subsequent new methods below is async, it is
         * safer to just run a dummy async code right at the beginning.
         */
        await new Promise((res) =>
          setTimeout(() => {
            res(() => {});
          }, DefaultLightboxDelay),
        );
        const pubId = assertEnum(
          PublicationShortname,
          Cookies.get(COOKIE_KEY_PUBS_ID) ?? "",
        );
        const issueId = Cookies.get(COOKIE_ISSUE_ID);

        // If no PubID is found, user is redirect to eshop landing page
        if (!pubId || !issueId) {
          window.location.href = "/";
          return;
        }

        if (NO_AUTH_PUBLICATION.includes(pubId)) {
          return;
        }

        MySphLightboxHelper.concurrencyLimitReached({
          onContinue: () => onContinue(pubId, issueId), // TS tends to point null issue here despite having a nullcheck on if above
          onCancel: () => (window.location.href = `/${pubId}`),
          onErrorConfirmation: () => (window.location.href = `/${pubId}`),
          svc: getServiceFlagsByPubsId(pubId),
          lan: getLanguageByPubId(pubId),
        });
      } catch (error: unknown) {
        console.error(
          "Failed to perform concurrency limit exceeded action.",
          error,
        );
      }
    }
    void concurrencyLimit();
  }, []);

  return <div className={styles.concurrencyPage} />;
}
