import cx from "classnames";
import { useContext, useEffect, useState } from "react";
import AppContext from "src/app/AppContext";
import { PublicationShortname } from "src/app/auth/serviceIdentifier";
import useDateFormat from "src/app/hooks/useDateFormat";
import { HomePageContext } from "src/app/pages/Home";

import IssueLoader from "../IssueLoader";
import styles from "./index.module.scss";

type TileProps = {
  publishedDate: string | undefined;
  coverImageUri: string | undefined;
  theme: PublicationShortname | null;
  toggleTileView: () => void;
};

type TileDateProps = {
  theme: PublicationShortname | null;
  publishedDate: string | undefined;
};

const TileDate = ({ theme, publishedDate }: TileDateProps) => {
  return (
    <>
      <p data-theme={theme} className={cx(styles.tileDay)}>
        {publishedDate &&
          useDateFormat({
            date: publishedDate as unknown as Date,
            shouldShowDayOfWeek: true,
          })}
      </p>
      <p data-theme={theme} className={cx(styles.tileDate)}>
        {publishedDate &&
          useDateFormat({
            date: publishedDate as unknown as Date,
          })}
      </p>
    </>
  );
};

//component for single issue tile
const Tile = ({
  publishedDate,
  toggleTileView,
  coverImageUri,
  theme,
}: TileProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const { selectedDateMap, setSelectedDateMap } = useContext(HomePageContext);
  const { pubId } = useContext(AppContext);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    if (isLoaded) {
      setIsLoaded(false);
    }
  }, [theme]);

  return (
    <button
      key={publishedDate}
      onClick={() => {
        if (publishedDate) {
          const updatedSelectedDateMap = new Map([
            ...selectedDateMap.entries(),
          ]).set(pubId, publishedDate as unknown as Date);
          setSelectedDateMap(updatedSelectedDateMap);
        }
        toggleTileView();
      }}
    >
      <IssueLoader
        forTileView={true}
        imageSrc={coverImageUri}
        isLoaded={isLoaded}
        handleImageLoad={handleImageLoad}
      />
      {isLoaded && <TileDate theme={theme} publishedDate={publishedDate} />}
    </button>
  );
};

export default Tile;
