import cx from "classnames";
import React, { ReactElement } from "react";

import styles from "./index.module.scss";

export default function LoadingBubbles(): ReactElement {
  return (
    <div className={cx(styles.loaderWrapper)}>
      <div className={cx(styles.bubblesWrapper)}>
        <div className={cx([styles.bubbles, styles.b1])} />
        <div className={cx([styles.bubbles, styles.b2])} />
        <div className={cx([styles.bubbles, styles.b3])} />
        <div className={cx([styles.bubbles, styles.b4])} />
        <div className={cx([styles.bubbles, styles.b5])} />
      </div>
    </div>
  );
}
