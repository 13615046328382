import cx from "classnames";
import { useContext } from "react";

import { DrawerPageContext } from "../DrawerContainer";
import styles from "./index.module.scss";

type DayIcnProps = {
  day: string;
  index: number;
};

export const DayIcn = ({ day, index }: DayIcnProps) => {
  const { theme } = useContext(DrawerPageContext);

  return (
    <div key={`day-${index}`} className={cx(styles.dayTxt)} data-theme={theme}>
      {day}
    </div>
  );
};
