import cx from "classnames";
import { ReactElement } from "react";
import tickArrow from "src/app/assets/icons/icon-done-tick.svg";
import leftArrow from "src/app/assets/icons/icon-left-arrow.svg";
import rightArrow from "src/app/assets/icons/icon-right-arrow.svg";

import styles from "./index.module.scss";

export enum CarouselButtonTheme {
  BACK,
  NEXT,
  CLOSE,
}

type Props = {
  onClick?: () => void;
  onClose?: () => void;
  isDisabled?: boolean;
  theme: CarouselButtonTheme;
  alt: string;
};

export default function CarouselButton({
  onClick,
  onClose,
  theme,
  isDisabled,
  alt,
}: Props): ReactElement {
  const { BACK, NEXT, CLOSE } = CarouselButtonTheme;
  return (
    <button
      onClick={theme === CLOSE ? onClose : onClick}
      className={cx(
        styles.carouselButton,
        theme === NEXT && styles.carouselButtonNext,
        theme === BACK && styles.carouselButtonBack,
        theme === CLOSE && styles.carouselButtonClose,
      )}
      disabled={isDisabled}
    >
      <img
        src={
          theme === BACK ? leftArrow : theme === NEXT ? rightArrow : tickArrow
        }
        alt={alt}
      />
    </button>
  );
}
