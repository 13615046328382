import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  NO_CALENDER_VIEW_PUBLICATION,
  PublicationShortname,
} from "@src/app/auth/serviceIdentifier";
import cx from "classnames";
import { ReactElement, useState } from "react";
import Lottie from "react-lottie";
import Slider from "react-slick";
import pastIssueImage from "src/app/assets/archive.json";
import phoneImage from "src/app/assets/epaper.json";
import articleViewImage from "src/app/assets/html.json";
import quickAccessImage from "src/app/assets/menu.json";
import tileImage from "src/app/assets/tile.json";
import { TGtmData } from "src/app/pages/Home";
import { EP_GA_EVENT_NAME, PAGE_NAME } from "src/trackers/googleAnalytics";

import { Modal } from "../Modal";
import CarouselButton, { CarouselButtonTheme } from "./CarouselButton";
import styles from "./index.module.scss";
import { OnboardingSlide } from "./OnboardingSlide";

const onBoardingImages = [
  phoneImage,
  articleViewImage,
  pastIssueImage,
  quickAccessImage,
  tileImage,
];

type Props = {
  onClose: (pagination: number) => void;
  handleGAEvent: (eventName: EP_GA_EVENT_NAME, gtmData: TGtmData) => void;
  publicationId: PublicationShortname | null;
};

export function OnboardingModal({
  onClose,
  handleGAEvent,
  publicationId,
}: Props): ReactElement {
  const [currentSlide, setCurrentSlide] = useState(0);

  const showAllSlides = !(
    publicationId && NO_CALENDER_VIEW_PUBLICATION.includes(publicationId)
  );

  const hideArticleAndCalenderSlide =
    publicationId === PublicationShortname.ZB ||
    publicationId === PublicationShortname.SM;

  const getNumberOfSlides = () => {
    if (showAllSlides) {
      //all slides visible for publications are not ZB/SM or weekly publications
      return onBoardingImages.length;
    } else if (hideArticleAndCalenderSlide) {
      //show 3 slides when publication is ZB/SM
      return onBoardingImages.length - 2;
    } else {
      //show 4 slides when publications weekly publications
      return onBoardingImages.length - 1;
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current: number, next: number) => {
      // to call page view GA event on forward button click
      if (currentSlide < next) {
        handleGAEvent(EP_GA_EVENT_NAME.PAGE_VIEW, {
          chapter1: PAGE_NAME.ONBOARDING,
          pageName: PAGE_NAME.ONBOARDING,
          pagination: next + 1,
        });
      }

      setCurrentSlide(next);
    },
    nextArrow:
      currentSlide >= getNumberOfSlides() - 1 ? (
        <CarouselButton
          theme={CarouselButtonTheme.CLOSE}
          alt="done tick image"
          onClose={() => {
            onClose(getNumberOfSlides() + 1);
          }}
        />
      ) : (
        <CarouselButton
          theme={CarouselButtonTheme.NEXT}
          alt="next arrow image"
        />
      ),
    prevArrow: (
      <CarouselButton
        theme={CarouselButtonTheme.BACK}
        alt="back arrow image"
        isDisabled={currentSlide === 0}
      />
    ),
  };

  const getSlide = (index: number, data: any) => {
    return (
      <OnboardingSlide slideNumber={index} key={index}>
        <div
          className={cx({
            [styles.onBoardingImages]: true,
            [styles.imgShadow]: currentSlide !== 0,
          })}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: data,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
          />
        </div>
      </OnboardingSlide>
    );
  };

  return (
    <Modal
      onClose={() => {
        onClose(currentSlide + 1);
      }}
    >
      <div className={cx(styles.onBoardingModalBody)}>
        <Slider {...settings}>
          {onBoardingImages.map((data, index: number) => {
            //show all the slides when pubs are not ZB/SM and weekly pubs
            if (showAllSlides) {
              return getSlide(index, data);
            }

            if (hideArticleAndCalenderSlide) {
              //hide calender and article slide (i.e.2nd and 3rd slide as index is starting from 0) when pubs are ZB/SM
              if (index != 1 && index != 2) {
                return getSlide(index, data);
              }
            } else {
              //hide calender slide (i.e. 3rd slide as index is starting from 0) when pubs are ZB/SM and weekly pubs
              if (index != 2) {
                return getSlide(index, data);
              }
            }
          })}
        </Slider>
      </div>
    </Modal>
  );
}
