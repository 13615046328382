import { PublicationShortname } from "@src/app/auth/serviceIdentifier";
import dayjs from "dayjs";
import Cookies from "js-cookie";

// this cookie key name need to be consistent with the reader one
export const COOKIE_KEY_VISITOR_OBJECT = "COOKIE_KEY_VISITOR_OBJECT";
export const COOKIE_MYSPH_SID = "mysph-sid";

export type TViewerContext = {
  authStatus?: string;
  userProfile?: {
    login_id?: string;
    user_email?: string;
    visitor_id?: string;
    first_name?: string;
    last_name?: string;
    service_flags?: string[];
    concurrent_config?: string[];
  };
};

export type TVisitorContext = {
  visitorCategory: "Anonymous" | "Subscriber" | "Registered";
  visitorId: string;
  svc: string;
};

export const DEFAULT_VISITOR_CONTEXT: TVisitorContext = {
  visitorCategory: "Anonymous",
  visitorId: "",
  svc: "",
};

/**
 * get viewer context from cookies
 *
 * if it does not exist in the cookies, it will fetch from the API function, and store it in cookies
 */
export async function fetchVisitorContext(
  pubsId: PublicationShortname,
): Promise<TVisitorContext> {
  try {
    const vc = Cookies.get(COOKIE_KEY_VISITOR_OBJECT);
    if (vc != null) {
      const parsedVc = JSON.parse(vc);

      if (isVisitorContext(parsedVc)) {
        return parsedVc;
      }

      return DEFAULT_VISITOR_CONTEXT;
    }

    const response = await fetch(`/${pubsId}/api/viewerContext`, {
      method: "GET",
      credentials: "include",
    });

    if (response.ok) {
      const data = (await response.json()) as TViewerContext;

      if (data.authStatus === "AUTHENTICATED") {
        const newVc: TVisitorContext = {
          visitorCategory: "Subscriber",
          visitorId: data.userProfile?.visitor_id ?? "",
          svc: mergeServiceFlagsWithDelimitor(
            data.userProfile?.service_flags ?? [],
          ),
        };

        Cookies.set(COOKIE_KEY_VISITOR_OBJECT, JSON.stringify(newVc), {
          expires: dayjs().add(1, "hour").toDate(),
        });

        return newVc;
      }
    }

    return DEFAULT_VISITOR_CONTEXT;
  } catch (error: unknown) {
    console.error(error);

    return DEFAULT_VISITOR_CONTEXT;
  }
}

export function clearLoginSession(pubId: string): void {
  Cookies.remove(COOKIE_KEY_VISITOR_OBJECT);
  Cookies.remove(COOKIE_MYSPH_SID, { path: `/${pubId}` });
}

/**
 *
 * @param serviceFlags Service Flags fields returned by mySPH in array of strings
 * @returns {string} merged service flags using delimitor "|"
 */
function mergeServiceFlagsWithDelimitor(serviceFlags: string[]): string {
  return serviceFlags.join("|");
}

/**
 * accept an object with unknown type, to be verified whether it fits TVisitorContext type
 */
function isVisitorContext(obj: unknown): obj is TVisitorContext {
  const castObj = obj as TVisitorContext;

  return (
    castObj.visitorCategory != null &&
    castObj.visitorId != null &&
    castObj.svc != null
  );
}
