import cx from "classnames";
import { useContext } from "react";

import { HomePageContext } from "../../pages/Home";
import { CloseBtn } from "../CloseBtn";
import { DrawerPageContext } from "../DrawerContainer";
import styles from "./index.module.scss";

export const TopContainer = () => {
  const { toggleDrawer } = useContext(HomePageContext);
  const { setHideDrawer } = useContext(DrawerPageContext);

  const closeDrawer = () => {
    setHideDrawer(true);
    setTimeout(function () {
      toggleDrawer();
    }, 500);
  };
  return (
    <div className={cx(styles.topContainer)}>
      <CloseBtn onClick={closeDrawer} />
    </div>
  );
};
