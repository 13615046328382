import cx from "classnames";
import { ReactElement } from "react";

import { CloseBtn } from "../CloseBtn";
import styles from "./index.module.scss";

type Props = {
  children: string | JSX.Element;
  onClose: () => void;
};

export function Modal({ children, onClose }: Props): ReactElement {
  return (
    <div className={cx(styles.modalOverlay)}>
      <div className={cx(styles.modalWrapper)}>
        <div className={cx(styles.modal)}>
          <div className={cx(styles.closeBtn)}>
            <CloseBtn onClick={onClose} />
          </div>
          <div className={cx(styles.modalBody)}>{children}</div>
        </div>
      </div>
    </div>
  );
}
