import cx from "classnames";
import { useContext } from "react";

import { HomePageContext } from "../../pages/Home";
import { DateTable } from "../DateTable";
import { DayRow } from "../DayRow";
import styles from "./index.module.scss";

export const CalendarContainer = () => {
  const { calendarData, selectedDateMap } = useContext(HomePageContext);
  return (
    <div className={cx(styles.calendarContainer)}>
      <DayRow />
      <DateTable
        calendarData={calendarData}
        selectedDateMap={selectedDateMap}
      />
    </div>
  );
};
