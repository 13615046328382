import { createContext, Dispatch } from "react";
import {
  DEFAULT_VISITOR_CONTEXT,
  TVisitorContext,
} from "src/trackers/viewerContext";

import { PublicationShortname } from "./auth/serviceIdentifier";
import { TAction } from "./reducer";

const CONTEXT_PERSISTANCE_KEY = "EPAPER_CONTEXT";

export type TAppContext = {
  pubId: PublicationShortname;
  issueId?: number;
  isLighBoxLoaded?: boolean;
  visitorContext: TVisitorContext | null;
  dispatch: Dispatch<TAction>;
};

const defaultInitialState: TAppContext = {
  pubId: PublicationShortname.DEFAULT,
  issueId: undefined,
  isLighBoxLoaded: false,
  visitorContext: DEFAULT_VISITOR_CONTEXT,
  dispatch: () => null,
};

export function getInitialState(): TAppContext {
  if (typeof window === "undefined") {
    return defaultInitialState;
  }
  const persistance = localStorage.getItem(CONTEXT_PERSISTANCE_KEY);
  return persistance
    ? (JSON.parse(persistance) as TAppContext)
    : defaultInitialState;
}

export function persistToStorage(state: TAppContext) {
  if (typeof window !== "undefined") {
    localStorage.setItem(
      CONTEXT_PERSISTANCE_KEY,
      JSON.stringify({ ...state, isLighBoxLoaded: false }),
    );
  }
}

const AppContext = createContext<TAppContext>(getInitialState());

export default AppContext;
