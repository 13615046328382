import { TAppContext } from "./AppContext";

export enum Actions {
  SET_PUBID = "SET_PUBID",
  SET_ISSUEID = "SET_ISSUEID",
  SET_VIEWERCONTEXT = "SET_VIEWERCONTEXT",
  SET_ISLIGHTBOXLOADED = "SET_ISLIGHTBOXLOADED",
}

export type TAction = { type: Actions; payload: Partial<TAppContext> };

function reducer(state: TAppContext, action: TAction): TAppContext {
  switch (action.type) {
    case Actions.SET_ISSUEID:
      return { ...state, issueId: action.payload.issueId };
    case Actions.SET_PUBID:
      if (!action.payload.pubId) return state;
      return { ...state, pubId: action.payload.pubId };
    case Actions.SET_ISLIGHTBOXLOADED:
      return { ...state, isLighBoxLoaded: action.payload.isLighBoxLoaded };
    case Actions.SET_VIEWERCONTEXT:
      if (!action.payload.visitorContext) return state;
      return { ...state, visitorContext: action.payload.visitorContext };
    default:
      return state;
  }
}

export default reducer;
