import cx from "classnames";

import { DayIcn } from "../DayIcn";
import styles from "./index.module.scss";

export const DayRow = () => {
  const days = ["M", "T", "W", "T", "F", "S", "S"];

  return (
    <div className={cx(styles.dayRow)}>
      {days.map((day, index) => {
        return <DayIcn key={`${day}-${index}`} day={day} index={index} />;
      })}
    </div>
  );
};
