import { MySphLightboxHelper } from "@sphtech/web2-core/auth";
import Cookies from "js-cookie";
import { ReactElement, useEffect } from "react";
import {
  getServiceFlagsByPubsId,
  PublicationShortname,
} from "src/app/auth/serviceIdentifier";
import LoadingBubbles from "src/app/components/LoadingBubbles";

import assertEnum from "../../../../.web2/web2-helpers/assertEnum";
import { COOKIE_KEY_PUBS_ID } from "../Home";
import { SUBSCRIBE_URL } from "src/app/data";

const MYSPH_LIGHTBOX_INIT_TIME = 2000;

export default function UnauthenticatedPage(): ReactElement {
  useEffect(() => {
    const cookiePubsId = Cookies.get(COOKIE_KEY_PUBS_ID) ?? "";
    const pubId = assertEnum(PublicationShortname, cookiePubsId);

    if (pubId != null) {
      localStorage.setItem("sph-epaper-cookie-path", `/${pubId}`);

      const currentUrl = new URL(window.location.href);

      const authOptions = {
        postLoginUrl: `${currentUrl.protocol}//${currentUrl.host}/${pubId}`,
        lan: "en",
        svc: getServiceFlagsByPubsId(pubId),
      };

      // setTimeout is used here due to the race condition between the MySphLightbox init and startRedirectAuth
      // future improvement is to use a promise / callback to ensure MySphLightbox is initialized before calling startRedirectAuth
      setTimeout(() => {
        MySphLightboxHelper.startRedirectAuth(authOptions);
      }, MYSPH_LIGHTBOX_INIT_TIME);
    } else {
      window.location.href = SUBSCRIBE_URL;
    }
  }, []);

  return <LoadingBubbles />;
}
