import cx from "classnames";
import { useMemo } from "react";
import { PublicationShortname } from "src/app/auth/serviceIdentifier";
import { TArchive } from "src/app/pages/Home/api";

import IssueLoader from "../IssueLoader";
import styles from "./index.module.scss";
import Tile from "./Tile";

type Props = {
  issues: Record<string, TArchive[]> | null;
  theme: PublicationShortname | null;
  toggleTileView: () => void;
};

function TileView({ issues, theme, toggleTileView }: Props) {
  const issueLoader: number[] = useMemo(
    () => Array.from({ length: 14 }, (_, i) => i),
    [],
  );

  return (
    <div className={cx(styles.tileViewContainer)}>
      <div className={cx(styles.tileView)}>
        {issues
          ? Object.keys(issues)
              .reverse()
              .map((issue: string) => {
                return (
                  <div
                    key={issues[issue][0]?.publishedDate}
                    className={cx(styles.issueTile)}
                  >
                    <Tile
                      publishedDate={issues[issue][0].publishedDate}
                      coverImageUri={issues[issue][0].coverImageUri}
                      theme={theme}
                      toggleTileView={toggleTileView}
                    />
                  </div>
                );
              })
          : issueLoader.map((index) => (
              <div key={index} className={cx(styles.issueTile)}>
                <IssueLoader forTileView={true} />
              </div>
            ))}
      </div>
    </div>
  );
}

export default TileView;
