import cx from "classnames";
import icClose from "src/app/assets/icons/icon-close.svg";

import styles from "./index.module.scss";

type CloseBtnProps = {
  onClick: () => void;
};

export const CloseBtn = ({ onClick }: CloseBtnProps) => {
  return (
    <button
      className={cx(styles.icon__container)}
      data-testid="closeBtn"
      onClick={onClick}
    >
      <img src={icClose} alt="close_icon" className={cx(styles.icon__img)} />
    </button>
  );
};
