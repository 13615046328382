import cx from "classnames";
import React from "react";
import { PublicationShortname } from "src/app/auth/serviceIdentifier";

import styles from "./index.module.scss";

type Props = {
  children: string | JSX.Element;
  text: string;
  position?: "top" | "bottom" | "left" | "right";
  theme: PublicationShortname | null;
};

function Tooltip({ children, text, position = "top", theme }: Props) {
  const getTooltipPosition = () => {
    switch (position) {
      case "bottom":
        return styles.bottom;
      case "left":
        return styles.left;
      case "right":
        return styles.right;
      default:
        return styles.top;
    }
  };

  /**
   * this function is used to check device
   * if the device is mobile or tablet then it will return true
   * it is used to hide the tooltip for mobile and tablet devices
   */
  const isMobile = () => {
    if (typeof window !== "undefined") {
      const userAgent = navigator.userAgent.toLowerCase();
      return /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(
        userAgent,
      );
    }
  };

  return (
    <div className={cx(styles.tooltip)}>
      {children}
      {!isMobile() && (
        <span
          className={cx([styles.tooltiptext, getTooltipPosition()])}
          data-theme={theme}
        >
          {text}
        </span>
      )}
    </div>
  );
}

export default Tooltip;
