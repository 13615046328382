import cx from "classnames";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { brandLogo } from "src/app/data";

import styles from "./index.module.scss";

// Define props using type
type Props = {
  secondaryPublicationId: string;
  publicationId: string;
  navTabBarIndex?: number | null;
};

export default function MultiTitle({
  secondaryPublicationId,
  publicationId,
  navTabBarIndex,
}: Props): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClickSecondaryPage = () => {
    if (secondaryPublicationId) {
      navigate(`/${secondaryPublicationId}`);
    }
  };

  const handleClickPrimaryPage = () => {
    if (publicationId) {
      navigate(`/${publicationId}`);
    }
  };

  const renderTab = (pubId: string, onClick: () => void, isActive: boolean) => (
    <button
      data-theme={publicationId}
      className={
        isActive ? cx(styles.BrandTabs, styles.activeTab) : cx(styles.BrandTabs)
      }
      onClick={onClick}
    >
      <div className={cx(styles.Tab)}>
        <div className={cx(styles.BrandLogo)}>
          <img src={brandLogo[pubId]} alt={pubId} />
        </div>
        <div className={cx(styles.Label)}>{t(`home:multiTitle.${pubId}`)}</div>
      </div>
    </button>
  );

  return (
    <>
      {secondaryPublicationId && publicationId && (
        <div className={cx(styles.NavBar)}>
          {navTabBarIndex === 1 ? (
            <>
              {renderTab(publicationId, handleClickPrimaryPage, true)}
              {renderTab(
                secondaryPublicationId,
                handleClickSecondaryPage,
                false,
              )}
            </>
          ) : (
            <>
              {renderTab(
                secondaryPublicationId,
                handleClickSecondaryPage,
                false,
              )}
              {renderTab(publicationId, handleClickPrimaryPage, true)}
            </>
          )}
        </div>
      )}
    </>
  );
}
