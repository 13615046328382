import Cookies from "js-cookie";
import { ReactElement, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import assertEnum from "../../../../.web2/web2-helpers/assertEnum";
import {
  NO_AUTH_PUBLICATION,
  PublicationShortname,
  getLanguageByPubId,
  getSubscribeNowUrlByPubId,
} from "src/app/auth/serviceIdentifier";

import logo from "../../assets/SPH24-Logo.svg";
import subscribeStatusIcon from "../../assets/subscribe-status-icon.svg";
import { COOKIE_KEY_PUBS_ID } from "../Home";

import styles from "./SubscribePage.module.scss";
import AppContext from "src/app/AppContext";

export default function SubscribePage(): ReactElement {
  const { i18n, t } = useTranslation();
  const [notNowRoute, setNotNowRoute] = useState<string | null>(null);
  const [subscribeNow, setSubscribeNow] = useState<string | null>(null);
  const [lang, setLang] = useState<string>("en");
  const { pubId } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (pubId) {
      if (NO_AUTH_PUBLICATION.includes(pubId)) {
        navigate(`/${pubId}`);
      } else {
        setNotNowRoute(`/${pubId}`);
        setSubscribeNow(getSubscribeNowUrlByPubId(pubId));
        setLang(getLanguageByPubId(pubId));
      }
    }
  }, [pubId, navigate]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const handleNotNow = () => {
    if (notNowRoute) {
      navigate(notNowRoute);
    }
  };

  const handleSubscribeNow = () => {
    if (subscribeNow) {
      window.location.href = subscribeNow;
    }
  };

  return (
    <div className={styles.subscribePage}>
      <div className={styles.subscribePageContainer}>
        <div className={styles.subscribePageContainerHeader}>
          <div className={styles.logo}>
            <img src={logo} alt="Logo" />
          </div>
        </div>
        <div className={styles.subscribePageContainerStatusIcon}>
          <img src={subscribeStatusIcon} alt="Subscribe status icon" />
        </div>
        <div className={styles.subscribePageContainerTitle}>
          {t("subscribe:title")}
        </div>
        <div className={styles.subscribePageContainerDescription}>
          {t("subscribe:body")}
        </div>
        <div className={styles.subscribePageContainerSubscribeNowButton}>
          <button onClick={handleSubscribeNow}>{t("subscribe:cta")}</button>
        </div>
        <div className={styles.subscribePageContainerNotNowButton}>
          <button onClick={handleNotNow}>
            {t("subscribe:backToPublicationLink")}
          </button>
        </div>
      </div>
    </div>
  );
}
