export type BaseRouteParam = Record<string, unknown>;
export type ArticleRouteParam = BaseRouteParam & { id: string };
export type SectionRouteParam = BaseRouteParam & {
  level1: string;
  level2?: string;
};
export type RouteParam = BaseRouteParam | ArticleRouteParam | SectionRouteParam;
export enum Language {
  en = "en",
  zh = "zh",
  ms = "ms",
  ta = "ta",
}
