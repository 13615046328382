import cx from "classnames";
import dayjs from "dayjs";
import { useContext } from "react";

import { DrawerPageContext } from "../DrawerContainer";
import styles from "./index.module.scss";

type IssueThumbnailProps = {
  onClick?: () => void;
  imageUri: string;
  title: any;
  hideDrawer?: boolean;
};
export const IssueThumbnail = ({
  onClick,
  imageUri,
  title,
  hideDrawer,
}: IssueThumbnailProps) => {
  const { swipeRight, enterLeft } = useContext(DrawerPageContext);

  return (
    <div
      className={cx({
        [styles.issueTn]: true,
        [styles.hide]: hideDrawer,
        [styles.swipeRight]: swipeRight !== undefined && swipeRight,
        [styles.swipeLeft]: swipeRight !== undefined && !swipeRight,
        [styles.enterLeft]: enterLeft !== undefined && enterLeft,
        [styles.enterRight]: enterLeft !== undefined && !enterLeft,
      })}
    >
      <img
        className={cx(styles.issueTnImg)}
        src={imageUri}
        alt={dayjs(title).format(`[tn-]YYYYMMDD`)}
        onClick={onClick}
        role="button"
      />
    </div>
  );
};
