import { MySphLightboxHelper } from "@sphtech/web2-core/auth";
import { PublicationShortname } from "@src/app/auth/serviceIdentifier";
import cx from "classnames";
import type { ReactElement } from "react";
import logoutIcon from "src/app/assets/icons/logout.svg";
import { getCookiePath } from "src/app/auth/cookiePath";
import { clearLoginSession } from "src/trackers/viewerContext";

import styles from "./index.module.scss";

type Props = {
  theme: PublicationShortname;
  handleLogout: () => void;
};

export default function LogoutButton({
  theme,
  handleLogout,
}: Props): ReactElement {
  const getPostLogoutUrl = () => window.location.href;

  const onLogout = () => {
    handleLogout();
    clearLoginSession(theme);
    MySphLightboxHelper.openLogout({
      getPostLogoutUrl,
      getCookiePath,
    });
  };

  return (
    <button
      className={cx(styles.logoutButton)}
      onClick={onLogout}
      data-theme={theme}
    >
      <img src={logoutIcon} alt="logout icon" />
    </button>
  );
}
