import { MySphLightboxHelper } from "@sphtech/web2-core/auth";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import AppContext from "@src/app/AppContext";
import Cookies from "js-cookie";
import { ReactElement, useContext, useEffect } from "react";
import { COOKIE_PATH_KEY } from "src/app/auth/cookiePath";
import {
  getLanguageByPubId,
  getPubIdBySvc,
} from "src/app/auth/serviceIdentifier";

import { COOKIE_KEY_PUBS_ID } from "../Home";
import { CorpCallbackPagePayload } from "./index.server";

type RouteContext = CustomContext<{
  payload: CorpCallbackPagePayload;
}>;

export default function CorpCallbackPage(): ReactElement {
  const { context }: RouteContext = useRouteContext();
  const { isLighBoxLoaded } = useContext(AppContext);

  useEffect(() => {
    if (isLighBoxLoaded) {
      try {
        const svc = context.payload.svc;

        if (!svc) {
          throw new Error("SVC parameter is missing");
        }

        const pubId = getPubIdBySvc(svc);
        if (!pubId) {
          throw new Error(`Invalid PubID: ${pubId}`);
        }
        Cookies.set(COOKIE_KEY_PUBS_ID, pubId);
        localStorage.setItem(COOKIE_PATH_KEY, `/${pubId}`);
        const lan = getLanguageByPubId(pubId);

        MySphLightboxHelper.startRedirectAuth({
          postLoginUrl: `https://${window.location.host}/${pubId}`,
          lan,
          svc,
        });
      } catch (error: unknown) {
        console.error("Failed to start OIDC redirect auth.", error);
      }
    }
  }, [isLighBoxLoaded]);

  return <div />;
}
