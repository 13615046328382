import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enCommon from "./locales/en/common.json";
import enHome from "./locales/en/home.json";
import enSubscribe from "./locales/en/subscribe.json";
import enOnboarding from "./locales/en/onboarding.json";
import enError from "./locales/en/error.json";

import zhCommon from "./locales/zh/common.json";
import zhHome from "./locales/zh/home.json";
import zhSubscribe from "./locales/zh/subscribe.json";
import zhOnboarding from "./locales/zh/onboarding.json";
import zhError from "./locales/zh/error.json";

import msCommon from "./locales/ms/common.json";
import msHome from "./locales/ms/home.json";
import msSubscribe from "./locales/ms/subscribe.json";
import msOnboarding from "./locales/ms/onboarding.json";
import msError from "./locales/ms/error.json";

import taCommon from "./locales/ta/common.json";
import taHome from "./locales/ta/home.json";
import taSubscribe from "./locales/ta/subscribe.json";
import taOnboarding from "./locales/ta/onboarding.json";
import taError from "./locales/ta/error.json";

// Configure i18n
i18n.use(initReactI18next).init({
  supportedLngs: ["en", "zh", "ms", "ta"],
  lng: "en", // Default language
  fallbackLng: "en",
  ns: ["common", "home", "subscribe", "error"], // Namespaces for translations
  defaultNS: "common", // Default namespace
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      common: enCommon,
      home: enHome,
      subscribe: enSubscribe,
      onboarding: enOnboarding,
      error: enError,
    },
    zh: {
      common: zhCommon,
      home: zhHome,
      subscribe: zhSubscribe,
      onboarding: zhOnboarding,
      error: zhError,
    },
    ms: {
      common: msCommon,
      home: msHome,
      subscribe: msSubscribe,
      onboarding: msOnboarding,
      error: msError,
    },
    ta: {
      common: taCommon,
      home: taHome,
      subscribe: taSubscribe,
      onboarding: taOnboarding,
      error: taError,
    },
  },
});

export default i18n;
