export const COOKIE_PATH_KEY = "sph-epaper-cookie-path";

/**
 * Extracts the first path segment from the current URL and saves it to local storage.
 *
 * If the current URL does not contain any paths, an error is thrown.
 */
export const saveCookiePath = () => {
  const currentUrlPath = new URL(window.location.href).pathname;
  const split = currentUrlPath.split("/");

  if (split.length < 2 || split[1] === "") {
    throw new Error(
      "Cannot extract cookie path from current URL - URL has no path segments.",
    );
  }

  const cookiePath = `/${split[1]}`;
  localStorage.setItem(COOKIE_PATH_KEY, cookiePath);
};

/**
 * Retrieves the cookie path from local storage and removes it from local storage.
 *
 * If the cookie path cannot be found in local storage, we must throw an error
 * to stop the login process from proceeding as we don't want to end up creating
 * the authentication cookie in the wrong path. The cookie must be created on the
 * path returned by this function.
 *
 * @returns The cookie path that was saved in local storage.
 * @throws An error is thrown if the cookie path is not found in local storage.
 */
export const getCookiePath = () => {
  const cookiePath = localStorage.getItem(COOKIE_PATH_KEY);
  if (cookiePath) {
    localStorage.removeItem(COOKIE_PATH_KEY);
    return cookiePath;
  } else {
    throw new Error("Failed to get cookie path - Cookie path was not set.");
  }
};
