import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { useMySphLightbox } from "@sphtech/web2-core/auth";
import React, { useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import AppContext, {
  getInitialState,
  persistToStorage,
} from "src/app/AppContext";
import {
  getLanguageByPubId,
  getServiceFlagsByPubsId,
  NO_AUTH_PUBLICATION,
  PublicationShortname,
} from "src/app/auth/serviceIdentifier";
import reducer, { Actions } from "src/app/reducer";
import { GTM_ID } from "src/trackers/googleAnalytics";
import { fetchVisitorContext } from "src/trackers/viewerContext";

import {
  getClientId,
  getLightboxUrl,
} from "../../../../.web2/web2-helpers/auth/MySphConfig";
type Props = {
  children: React.ReactElement;
};

export default function AppContextHOC({ children }: Props): React.ReactElement {
  const [state, dispatch] = useReducer(reducer, getInitialState());

  const location = useLocation();

  /**
   * MySPH Integration guide: https://sph.atlassian.net/wiki/x/V4GqY
   */
  useMySphLightbox({
    lightboxUrl: getLightboxUrl(),
    clientId: getClientId(),
    onLoad: () => {
      dispatch({
        type: Actions.SET_ISLIGHTBOXLOADED,
        payload: { isLighBoxLoaded: true },
      });
    },
    serviceIdentifier: getServiceFlagsByPubsId(
      state.pubId === PublicationShortname.DEFAULT ||
        NO_AUTH_PUBLICATION.includes(state.pubId)
        ? PublicationShortname.ST
        : state.pubId,
    ),
    lan: getLanguageByPubId(
      state.pubId === PublicationShortname.DEFAULT ||
        NO_AUTH_PUBLICATION.includes(state.pubId)
        ? PublicationShortname.ST
        : state.pubId,
    ),
  });

  useEffect(() => {
    persistToStorage(state);
  }, [state]);

  useEffect(() => {
    if (
      state.pubId !== PublicationShortname.DEFAULT &&
      !NO_AUTH_PUBLICATION.includes(state.pubId)
    ) {
      void (async function () {
        await getVisitorContext(state.pubId);
      })();
    }
  }, [state.pubId, location]);

  const getVisitorContext = async (
    pubsId: PublicationShortname,
  ): Promise<void> => {
    try {
      const visitorContext = await fetchVisitorContext(pubsId);
      dispatch({
        type: Actions.SET_VIEWERCONTEXT,
        payload: { visitorContext },
      });
    } catch (error: unknown) {
      console.error("failed to get viewerContext :: ", error);
    }
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      <GTMProvider
        state={{
          id: GTM_ID,
        }}
      >
        <div data-theme={state.pubId}>{children}</div>
      </GTMProvider>
    </AppContext.Provider>
  );
}
