export type TIssue = {
  id?: number;
  coverImageUri?: string;
  readerUrl?: string;
  readerUrlWithPubs?: string;
};

type TIssueResponse = { issue?: TIssue };

export type ArchiveResponse = {
  issues?: TArchive[];
};

export type TArchive = {
  id?: number;
  coverImageUri?: string;
  publishedDate?: string;
};

export const fetchArchives = async (pubsId: string) => {
  const resp = await fetch(`/_plat/api/v1/${pubsId}/archives`);

  const data = (await resp.json()) as ArchiveResponse;
  return data;
};

export const getIssueById = async ({
  issueId,
  pubId,
}: {
  issueId: number;
  pubId: string;
}) => {
  const resp = await fetch(`/_plat/api/v1/${pubId}/issue/${issueId}`);

  const jsonResp = (await resp.json()) as TIssueResponse;
  return jsonResp.issue;
};
