import {
  NO_CALENDER_VIEW_PUBLICATION,
  PublicationShortname,
} from "@src/app/auth/serviceIdentifier";
import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import icTile from "src/app/assets/icons/archive.svg";
import icCalendar from "src/app/assets/icons/calender.svg";
import icClose from "src/app/assets/icons/close.svg";
import icInfo from "src/app/assets/icons/info.svg";

import LogoutButton from "../LogoutButton";
import Tooltip from "../Tooltip";
import styles from "./index.module.scss";

type Props = {
  publicationId: PublicationShortname | null;
  handleLogout: () => void;
  openModal: () => void;
  toggleDrawer: () => void;
  toggleTileView: () => void;
  isLoggedIn: boolean;
  showTileView: boolean;
};

function Header({
  publicationId,
  handleLogout,
  isLoggedIn,
  toggleDrawer,
  toggleTileView,
  openModal,
  showTileView,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className={cx(styles.headerContainer)}>
      {showTileView ? (
        <div className={cx(styles.closeButton)}>
          <img
            src={icClose}
            alt="icon-close"
            onClick={toggleTileView}
            data-testid="infoBtn"
          />
        </div>
      ) : (
        <>
          <div>
            {isLoggedIn && (
              <Tooltip
                text={t("home:tooltip.Logout")}
                position="right"
                theme={publicationId}
              >
                <LogoutButton
                  theme={publicationId}
                  handleLogout={handleLogout}
                />
              </Tooltip>
            )}
          </div>
          <div className={cx(styles.rightSideHeader)}>
            <Tooltip
              text={t("home:tooltip.Onboarding")}
              position="bottom"
              theme={publicationId}
            >
              <img
                src={icInfo}
                alt="icon-information"
                onClick={openModal}
                data-testid="infoBtn"
              />
            </Tooltip>
            {/* Hide calender view for non-daily publication like TABLA and BM */}
            {publicationId &&
              !NO_CALENDER_VIEW_PUBLICATION.includes(publicationId) && (
                <Tooltip
                  text={t("home:tooltip.CalenderView")}
                  position="bottom"
                  theme={publicationId}
                >
                  <img
                    src={icCalendar}
                    alt="icon-information"
                    onClick={toggleDrawer}
                    data-testid="infoBtn"
                  />
                </Tooltip>
              )}

            <Tooltip
              text={t("home:tooltip.TileView")}
              position="left"
              theme={publicationId}
            >
              <img
                src={icTile}
                alt="icon-tile"
                onClick={toggleTileView}
                data-testid="drawerBtn"
              />
            </Tooltip>
          </div>
        </>
      )}
    </div>
  );
}

export default Header;
