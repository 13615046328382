import cx from "classnames";
import { useContext } from "react";
import useDateFormat from "src/app/hooks/useDateFormat";

import { DrawerPageContext } from "../DrawerContainer";
import styles from "./index.module.scss";

type FocusedDaynDateProps = {
  date: Date;
  theme?: string;
};
export const FocusedDaynDate = ({
  date = new Date(),
  theme,
}: FocusedDaynDateProps) => {
  const { swipeRight, enterLeft } = useContext(DrawerPageContext);
  return (
    <div
      className={cx({
        [styles.focusedDate]: true,
        [styles.swipeRight]: swipeRight !== undefined && swipeRight,
        [styles.swipeLeft]: swipeRight !== undefined && !swipeRight,
        [styles.enterLeft]: enterLeft !== undefined && enterLeft,
        [styles.enterRight]: enterLeft !== undefined && !enterLeft,
      })}
      data-theme={theme}
    >
      <div className={cx(styles.focusedDayTxt)} data-theme={theme}>
        {useDateFormat({ date, shouldShowDayOfWeek: true })}
      </div>
      <div className={cx(styles.focusedDateTxt)} data-theme={theme}>
        {useDateFormat({ date })}
      </div>
    </div>
  );
};
