import cx from "classnames";
import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import { useContext } from "react";
import AppContext from "src/app/AppContext";
import { PublicationShortname } from "src/app/auth/serviceIdentifier";
import { getScreenOrientation } from "src/app/utils/getScreenOrientation";
import {
  EP_GA_EVENT_NAME,
  GAClickAction,
  getGAPublicationName,
  getTrackerDateString,
  PAGE_NAME,
  useEpGTMDispatch,
} from "src/trackers/googleAnalytics";

import { HomePageContext } from "../../pages/Home";
import { DateIcn } from "../DateIcn";
import styles from "./index.module.scss";

interface DateTableProps {
  calendarData: any;
  selectedDateMap: Map<PublicationShortname, Date>;
}

export const DateTable = ({
  calendarData,
  selectedDateMap,
}: DateTableProps) => {
  const { setSelectedDateMap } = useContext(HomePageContext);
  const { pubId, visitorContext } = useContext(AppContext);
  const gtmDispatch = useEpGTMDispatch();
  const past14Days = useMemo(() => {
    const weeks = [];
    let week = new Array<Date>();

    for (let i = 0; i < 3; i++) {
      const date = dayjs().subtract(i, "week");

      const startOfTheWeek = date.day(1);
      for (let j = 0; j < 7; j++) {
        const day = startOfTheWeek.add(j, "day");
        week.push(day.toDate());
      }

      weeks.unshift(week);
      week = new Array<Date>();
    }

    return weeks;
  }, []);

  const onDateClick = (date: Date) => {
    if (!calendarData || !calendarData[dayjs(date).format("YYYYMMDD")]) {
      return null;
    }

    if (pubId !== PublicationShortname.DEFAULT && visitorContext !== null) {
      const currentSelectedDate = selectedDateMap.get(pubId);
      if (currentSelectedDate) {
        gtmDispatch({
          eventName: EP_GA_EVENT_NAME.CUSTOM,
          eventDetail: {
            clickAction: GAClickAction.CLICK,
            clickLabel: `calendar::${dayjs(date).format("YYYY-MM-DD")}`,
            issue_pub_date: getTrackerDateString(currentSelectedDate),
            chapter1: PAGE_NAME.PORTAL,
            pageName: PAGE_NAME.PORTAL,
            visitorCategory: visitorContext.visitorCategory,
            visitorId: visitorContext.visitorId,
            svc: visitorContext.svc,
            orientation: getScreenOrientation(),
            publication_name: getGAPublicationName(pubId),
          },
        });
      }
    }
    const updatedSelectedDateMap = new Map([...selectedDateMap.entries()]).set(
      pubId,
      date,
    );
    setSelectedDateMap(updatedSelectedDateMap);
  };

  const renderPast14Days = useCallback(() => {
    return past14Days.map((week, index) => {
      return (
        <div key={`week-${index}`} className={cx(styles.calendarWeekRow)}>
          {week.map((date) => {
            return (
              <DateIcn
                key={date.toString()}
                date={date}
                isSelected={
                  dayjs(selectedDateMap.get(pubId)).format("YYYYMMDD") ===
                  dayjs(date).format("YYYYMMDD")
                    ? true
                    : false
                }
                isDisabled={
                  calendarData
                    ? calendarData[dayjs(date).format("YYYYMMDD")]
                      ? false
                      : true
                    : true
                }
                onClick={() => {
                  onDateClick(date);
                }}
              />
            );
          })}
        </div>
      );
    });
  }, [past14Days, selectedDateMap]);
  return <div>{renderPast14Days()}</div>;
};
