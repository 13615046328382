import cx from "classnames";
import dayjs from "dayjs";
import { useContext } from "react";
import AppContext from "src/app/AppContext";
import { PublicationShortname } from "src/app/auth/serviceIdentifier";
import { getScreenOrientation } from "src/app/utils/getScreenOrientation";
import {
  EP_GA_EVENT_NAME,
  GAClickAction,
  getGAPublicationName,
  getTrackerDateString,
  PAGE_NAME,
  useEpGTMDispatch,
} from "src/trackers/googleAnalytics";

import { HomePageContext } from "../../pages/Home";
import { DrawerPageContext } from "../DrawerContainer";
import { FocusedDaynDate } from "../FocusedDaynDate";
import { IssueThumbnail } from "../IssueThumbnail";
import styles from "./index.module.scss";

export const BottomContainer = () => {
  const { selectedDateMap, toggleDrawer, issues } = useContext(HomePageContext);
  const { setHideDrawer, hideDrawer } = useContext(DrawerPageContext);
  const { pubId, visitorContext } = useContext(AppContext);
  const gtmDispatch = useEpGTMDispatch();
  const currentSelectedDate = selectedDateMap.get(pubId);

  const closeDrawer = () => {
    if (
      pubId !== PublicationShortname.DEFAULT &&
      visitorContext !== null &&
      currentSelectedDate
    ) {
      gtmDispatch({
        eventName: EP_GA_EVENT_NAME.CUSTOM,
        eventDetail: {
          clickAction: GAClickAction.CLICK,
          clickLabel: `calendar::${dayjs(currentSelectedDate).format(
            "YYYY-MM-DD",
          )}::issue`,
          chapter1: PAGE_NAME.PORTAL,
          pageName: PAGE_NAME.PORTAL,
          issue_pub_date: getTrackerDateString(currentSelectedDate),
          visitorCategory: visitorContext.visitorCategory,
          visitorId: visitorContext.visitorId,
          svc: visitorContext.svc,
          orientation: getScreenOrientation(),
          publication_name: getGAPublicationName(pubId),
        },
      });
    }

    setHideDrawer(true);
    setTimeout(function () {
      toggleDrawer();
    }, 500);
  };

  return (
    <div className={cx(styles.bottomContainer)}>
      {currentSelectedDate && (
        <FocusedDaynDate date={currentSelectedDate} theme={pubId} />
      )}
      {issues && issues.length > 0 && issues[0].coverImageUri !== undefined ? (
        <IssueThumbnail
          onClick={closeDrawer}
          imageUri={issues[0].coverImageUri}
          title={""}
          hideDrawer={hideDrawer}
        />
      ) : null}
    </div>
  );
};
