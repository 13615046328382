import { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { match, MatchResult, pathToRegexp } from "path-to-regexp";

import { fetchCorpCallbackData } from "./pages/CorpCallback/index.server";
import { fetchHomeData } from "./pages/Home/Home.server";
import { routes } from "./routes";
import { RouteParam } from "./types/Data";

import brandLogoTm from "src/app/assets/brand-logo-tm.svg";
import brandLogoTabla from "src/app/assets/brand-logo-tabla.svg";
import brandLogoSm from "src/app/assets/brand-logo-sm.svg";
import brandLogoZb from "src/app/assets/brand-logo-zb.svg";

export type UrlMatch = {
  urlComponent: MatchResult<RouteParam>;
  variant: string;
};

// TODO: Move to .web2
export type WebFetcher<TPayload = unknown> = (
  urlMatch: UrlMatch,
) => Promise<TRouteWithoutRedirect<TPayload, string>>;

export const brandLogo: Record<string, string> = {
  zb: brandLogoZb,
  tm: brandLogoTm,
  sm: brandLogoSm,
  tabla: brandLogoTabla,
};

export const MIN_DESKTOP_WIDTH = 440;

export const SUBSCRIBE_URL: string = "https://subscribe.sph.com.sg";

// TODO: Type this and move to .web2
export const dataFetchers: Record<string, WebFetcher> = {
  "/st": fetchHomeData,
  "/bt": fetchHomeData,
  "/corpCallback/:svc": fetchCorpCallbackData,
  "/zb": fetchHomeData,
  "/bh": fetchHomeData,
  "/tm": fetchHomeData,
  "/sm": fetchHomeData,
  "/bm": fetchHomeData,
  "/tabla": fetchHomeData,
};

export const fetchContextForUrl = async (
  _url: string,
  variant: string = "default",
): Promise<unknown> => {
  const url = !_url ? "/" : _url;

  // No wildcard asterisk (*) and fallback parameters instead ((.*) or :splat*) due to Path-To-RegExp's limitations
  const transformedRoutes = routes.map((route) => {
    // catch-all route
    if (route === "*") {
      return "(.*)";
    } else {
      return route.replace("*", ":spat*");
    }
  });

  const routeMatch = transformedRoutes.find((rs) =>
    url.match(pathToRegexp(rs).regexp),
  );

  if (!routeMatch) {
    throw new Error(
      `Failed to prerender: could not find data fetcher for route ${JSON.stringify(
        url,
      )} against ${JSON.stringify(routes)}`,
    );
  }

  // use decodeURIComponent to get the original value of params
  // in case there's special char used in the url
  const urlComponent = match<RouteParam>(routeMatch, {
    decode: decodeURIComponent,
  })(url);

  const routeFetcher = dataFetchers[routeMatch] ?? null;
  if (!urlComponent || routeFetcher == null) {
    console.error(
      `Failed to find data loader or a match for the url: ${JSON.stringify(
        url,
      )}. (params: ${JSON.stringify(urlComponent)})`,
    );
    throw new Error("Failed to retrieve and call the data loader");
  }

  try {
    return await routeFetcher({ urlComponent, variant });
  } catch (err) {
    console.error(
      `prerender failed: to fetch data for route ${JSON.stringify(
        url,
      )}. (params: ${JSON.stringify(urlComponent)})`,
    );
    throw err;
  }
};
